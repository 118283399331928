<template>
  <div v-if="mCart" class="is-size-7">
    <article class="message is-warning">
      <div class="message-body">
        {{ $t('Components.Carts.CartSummary.Notification_Expires-in') }}
        {{ msToTime(expiresOn) }}
      </div>
    </article>

    <section class="section is-small">
      <div class="container">
        <table class="table is-fullwidth is-narrow">
          <tbody>
            <tr>
              <td></td>
              <td>#{{ mCart.Id }}</td>
            </tr>
            <tr>
              <td>Type</td>
              <td>
                {{ getCartTypeName(mCart.CartType) }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('Components.Carts.CartSummary.Table_Date') }}</td>
              <td>
                {{ mCart.StartDate | parseIsoDateStringToDate | dateFormat }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('Components.Carts.CartSummary.Table_Time') }}</td>
              <td>
                {{ mCart.StartMinutes | minutesToTime }} -
                {{ mCart.EndMinutes | minutesToTime }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('Components.Carts.CartSummary.Table_Name') }}</td>
              <td>{{ mCart.Name }}</td>
            </tr>
            <tr>
              <td>{{ $t('Components.Carts.CartSummary.Table_Company') }}</td>
              <td>{{ mCart.CompanyName }}</td>
            </tr>
            <tr>
              <td>{{ $t('Components.Carts.CartSummary.Table_Booker') }}</td>
              <td>{{ mCart.ProfileName }}</td>
            </tr>
            <tr>
              <td>{{ $t('Components.Carts.CartSummary.Table_Voucher') }}</td>
              <td>{{ mCart.VoucherName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <section class="section is-small">
      <div class="container">
        <table class="table is-fullwidth is-narrow">
          <tbody>
            <tr v-for="space in mCart.Spaces" :key="space.Id">
              <td>
                <div>{{ space.SpaceNumber }} {{ space.SpaceName }}</div>
                <div>
                  {{ space.StartMinutes | minutesToTime }} -
                  {{ space.EndMinutes | minutesToTime }}
                </div>
              </td>
              <td>
                <div>
                  <span>{{ space.Seats }} </span>
                  <span v-if="space.CalculationType === 0">{{
                    $t('Components.Carts.CartSummary.Table_Seats')
                  }}</span>
                  <span v-if="space.CalculationType === 1">{{
                    $t('Components.Carts.CartSummary.Table_People')
                  }}</span>
                </div>
                <div>
                  {{ space.SettingId | getSettingName }}
                </div>
              </td>
              <td width="60" class="has-text-right">
                {{
                  calculateDisplayPrice(
                    space.PricePerSeat,
                    space.TaxPercentage
                  ) | toCurrency
                }}
              </td>
              <td width="60" class="has-text-right">
                {{
                  calculateDisplayPrice(space.PriceTotal, space.TaxPercentage)
                    | toCurrency
                }}
              </td>
            </tr>
          </tbody>
          <tfoot v-if="showSubTotals">
            <tr>
              <td colspan="3">
                {{ $t('Components.Carts.CartSummary.Table_Subtotal') }}
              </td>
              <td width="60" class="has-text-right">
                {{
                  calculateDisplayPrice(spaceTotalExcl, spaceTaxPercentage)
                    | toCurrency
                }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>

    <section v-if="viewType === 'full'" class="section is-small">
      <div class="container">
        <table class="table is-fullwidth is-narrow">
          <tbody>
            <tr v-for="option in mCart.Options" :key="option.Id">
              <td width="25">{{ option.Amount }}x</td>
              <td>{{ option.OptionName }}</td>
              <td width="60" class="has-text-right">
                {{
                  calculateDisplayPrice(
                    option.PriceTotal,
                    option.TaxPercentage
                  ) | toCurrency
                }}
              </td>
            </tr>
          </tbody>
          <tfoot v-if="showSubTotals">
            <tr>
              <td colspan="2">
                {{ $t('Components.Carts.CartSummary.Table_Subtotal') }}
              </td>
              <td class="has-text-right">
                <span v-if="!showPriceInclTax">{{
                  optionsTotalExcl | toCurrency
                }}</span>
                <span v-if="showPriceInclTax">{{
                  optionsTotalIncl | toCurrency
                }}</span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>

    <section v-if="viewType === 'full'" class="section is-small">
      <div class="container">
        <table class="table is-fullwidth is-narrow">
          <tbody>
            <tr>
              <td>
                {{ $t('Components.Carts.CartSummary.Table_Total-excl-tax') }}
              </td>
              <td width="60" class="has-text-right">
                {{ mCart.TotalExclTax | toCurrency }}
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('Components.Carts.CartSummary.Table_Total-incl-tax') }}
              </td>
              <td width="60" class="has-text-right">
                {{ mCart.TotalInclTax | toCurrency }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <div class="has-text-right">
      <a @click="goToCart" class="button is-primary">{{
        $t('Components.Carts.CartSummary.Button_Open-cart')
      }}</a>
    </div>
  </div>
</template>

<script>
import cartProvider from '@/providers/cart'

export default {
  props: {
    cart: {
      default: null,
      type: Object
    },

    cartId: {
      default: 0,
      type: Number
    },

    displayMode: {
      default: 'full',
      type: String
    },

    locationId: {
      default: 0,
      type: Number
    },

    showSubTotals: {
      default: false,
      type: Boolean
    },

    showPriceInclTax: {
      default: false,
      type: Boolean
    },

    viewType: {
      default: 'full',
      type: String
    }
  },

  data() {
    return {
      cartTypes: [],
      currentDateTime: new Date().getTime(),
      expireDateTime: 0,
      expiresOn: 0,
      mCart: this.cart
    }
  },

  created() {
    if (this.mCart === null) {
      this.getCartById()
    } else {
      this.activateCountDown()
    }

    this.cartTypes.push({ Id: 0, Type: 'RegularReservation', Name: 'Regular' })
    this.cartTypes.push({
      Id: 1,
      Type: 'DoubleBooking',
      Name: 'Double booking'
    })
    this.cartTypes.push({ Id: 2, Type: 'Proposal', Name: 'Proposal' })
  },

  computed: {
    optionsTotalExcl() {
      let total = 0

      if (this.mCart && this.mCart.Options.length > 0) {
        for (let i = 0; i < this.mCart.Options.length; i++) {
          let option = this.mCart.Options[i]

          total = total + option.PriceTotal
        }
      }

      return total
    },

    optionsTotalIncl() {
      let total = 0

      if (this.mCart && this.mCart.Options.length > 0) {
        for (let i = 0; i < this.mCart.Options.length; i++) {
          let option = this.mCart.Options[i]

          total =
            total + option.PriceTotal * ((100 + option.TaxPercentage) / 100)
        }
      }

      return total
    }
  },

  methods: {
    getCartById() {
      cartProvider.methods.getCartById(this.cartId).then(response => {
        if (response.status === 200) {
          this.mCart = response.data

          this.activateCountDown()
        }
      })
    },

    getCartTypeName(type) {
      let typeIndex = this.cartTypes.findIndex(t => t.Type === type)
      if (typeIndex > -1) {
        return this.cartTypes[typeIndex].Name
      }
      return ''
    },

    calculateDisplayPrice(price, taxPercentage) {
      if (this.showPriceInclTax) {
        price = price * ((100 + taxPercentage) / 100)
      }

      return price
    },

    activateCountDown() {
      let self = this

      self.expireDateTime =
        self.mCart.CreatedOn + 60000 * self.mCart.MinutesToExpire
      self.expiresOn = self.expireDateTime - self.currentDateTime

      self.timer = window.setInterval(self.countdown, 1000)
    },

    countdown() {
      let expiresOn = this.expiresOn
      this.expiresOn = expiresOn - 1000

      if (this.expiresOn <= 0) {
        this.clearTimer()
      }
    },

    msToTime(s) {
      let ms = s % 1000
      s = (s - ms) / 1000
      let secs = s % 60
      s = (s - secs) / 60
      let mins = s % 60
      let hrs = (s - mins) / 60

      return (
        ('0' + hrs).slice(-2) +
        ':' +
        ('0' + mins).slice(-2) +
        ':' +
        ('0' + secs).slice(-2)
      )
    },

    clearTimer() {
      window.clearInterval(this.timer)
    },

    goToCart() {
      this.$emit('goToCart', this.mCart.Id)
    },

    goToTender(tenderId) {
      this.$router.push({
        name: `reservations-tenders-detail`,
        params: { tenderId: tenderId }
      })
    }
  }
}
</script>
